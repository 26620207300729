import { Component, Input } from '@angular/core';
import { ROOT } from '../../utils/routes';
import { MatDialog } from '@angular/material/dialog';
import { ContactComponent } from '../modals';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  ROOT = ROOT;
  @Input() inHome: boolean = false;

  constructor(private dialog: MatDialog) {}

  contactForm() {
    ContactComponent.open(this.dialog);
  }
}
