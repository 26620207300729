import { Component, Output, EventEmitter, Input, OnChanges, OnInit } from '@angular/core';

// Models
import { Region } from '../../../models/regions.models';

// Services
import { RegionsService } from '../../../services';

// Components
import { AbstractSubscriptionComponent } from '../../abtract-subscription/abstract-subscription.component';

@Component({
  selector: 'app-select-regions',
  templateUrl: './select-regions.component.html'
})
export class SelectRegionsComponent extends AbstractSubscriptionComponent implements OnInit, OnChanges {
  @Input() defaultRegion: Region;
  @Output() select = new EventEmitter<Region>();
  regions: Region[];

  constructor(private regionService: RegionsService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(this.regionService.getRegions().subscribe(regions => (this.regions = regions)));
  }

  ngOnChanges() {
    if (this.defaultRegion && this.defaultRegion.id) {
      this.select.emit(this.defaultRegion);
    }
  }

  isRegionSelected(region: Region) {
    return this.defaultRegion && this.defaultRegion.id === region.id;
  }

  selectRegion(event) {
    const regionToEmit = this.regions.find(region => region.name === event.target.value);
    this.select.emit(regionToEmit);
  }
}
