import { setUserSource } from './actions/user.subjects';
import { User } from '../../models/user.models';
import { Candidate, CandidatesCount } from '../../models/candidate.model';

interface UserState {
  shoppingCartCount: number;
  currentUser: User;
  activeCandidates: Candidate[];
  candidatesCount: CandidatesCount;
}

const state: UserState = {
  currentUser: {
    address: {
      street: null,
      city: null,
      postalCode: null,
      latitude: null,
      longitude: null
    },
    email: null,
    firstname: null,
    lastname: null,
    id: null,
    phoneNumber: null,
    title: null,
    category: null,
    autoEcole: null,
    contactEmail: null,
    formeJuridique: null,
    raisonSociale: null
  },
  activeCandidates: [],
  candidatesCount: { numArchived: 0, numActive: 0 },
  shoppingCartCount: 0
};

export const setCurrentUser = user => {
  state.currentUser = user;
  setUserSource.next();
};

export const setActiveCandidates = (candidates: Candidate[]) => {
  state.activeCandidates = candidates;
};

export const setShoppingCartCount = (shoppingCartCount: number) => {
  state.shoppingCartCount = shoppingCartCount;
};

export const setCandidatesCount = (count: CandidatesCount) => {
  state.candidatesCount = count;
};

export const getCurrentUser = () => state.currentUser;
export const getCurrentUserEmail = () => state.currentUser.email;
export const getActiveCandidates = () => state.activeCandidates;
export const getCandidatesCount = () => state.candidatesCount;
export const getCurrentUserId = () => state.currentUser.id;
export const getCurrentUserFirstname = () => state.currentUser.firstname;
export const getCurrentUserLastname = () => state.currentUser.lastname;
export const getCurrentUserStreet = () => (state.currentUser.address ? state.currentUser.address.street : '');
export const getShoppingCartCount = () => state.shoppingCartCount;
