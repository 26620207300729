import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PlaceAutocompleteComponent } from './place-autocomplete/place-autocomplete.component';
import { AbstractSubscriptionComponent } from './abtract-subscription/abstract-subscription.component';
import * as modalsComponents from './modals';
import * as selectsComponents from './select';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SwitcherComponent } from './switcher/switcher.component';

export const components = [
  HeaderComponent,
  FooterComponent,
  PlaceAutocompleteComponent,
  AbstractSubscriptionComponent,
  ...modalsComponents.modals,
  ...selectsComponents.select,
  BreadcrumbComponent,
  SwitcherComponent
];

export * from './header/header.component';
export * from './footer/footer.component';
export * from './place-autocomplete/place-autocomplete.component';
export * from './abtract-subscription/abstract-subscription.component';
export * from './modals';
export * from './select';
export * from './breadcrumb/breadcrumb.component';
export * from './switcher/switcher.component';
