import { SentryErrorHandler } from './sentry/sentry.service';
import { AccountService } from './account/account.service';
import { GoogleService } from './google/google.service';
import { ExaminationCenterService } from './examination-center/examination-center.service';
import { RegionsService } from './regions/regions.service';
import { DrivingSchoolService } from './driving-school/driving-school.service';
import { SessionsService } from './sessions/sessions.service';
import { GoogleAnalyticsService } from './analytics/google-analytics.service';
import { ProductService } from './product/product.service';
import { PrismicService } from './prismic/prismic.service';

export const services = [
  AccountService,
  GoogleService,
  ExaminationCenterService,
  RegionsService,
  DrivingSchoolService,
  SessionsService,
  SentryErrorHandler,
  GoogleAnalyticsService,
  ProductService,
  PrismicService
];

export * from './account/account.service';
export * from './google/google.service';
export * from './examination-center/examination-center.service';
export * from './regions/regions.service';
export * from './driving-school/driving-school.service';
export * from './sessions/sessions.service';
export * from './sentry/sentry.service';
export * from './analytics/google-analytics.service';
