import { KeycloakService } from 'keycloak-angular';

import { environment } from 'src/environments/environment';

// Utils
import {
  GOOGLE_AUTOCOMPLETE,
  GOOGLE_DETAILS,
  REGIONS,
  DEPARTMENTS,
  CES,
  COUNT_CES,
  FIND_CES,
  ACCOUNT,
  SESSIONS_FIND,
  PRODUCTS,
  CONTACT
} from 'src/app/shared/utils/endPoints';

export default function keycloakInitializer(kc: KeycloakService): () => Promise<boolean> {
  return () =>
    kc.init({
      config: {
        ...environment.keycloak
      },
      initOptions: {
        onLoad: 'check-sso'
      },
      bearerExcludedUrls: [
        REGIONS,
        DEPARTMENTS,
        CES,
        CONTACT,
        GOOGLE_AUTOCOMPLETE,
        GOOGLE_DETAILS,
        COUNT_CES,
        PRODUCTS,
        FIND_CES,
        SESSIONS_FIND,
        { url: `${ACCOUNT}$`, httpMethods: ['POST'] }
      ]
    });
}
