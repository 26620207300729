import { YesNoModalComponent } from './yes-no-modal/yes-no-modal.component';
import { OkModalComponent } from './ok-modal/ok-modal.component';
import { VideoModalComponent } from './video-modal/video-modal.component';
import { ContactComponent } from './contact/contact.component';

export const modals = [YesNoModalComponent, OkModalComponent, VideoModalComponent, ContactComponent];

export * from './yes-no-modal/yes-no-modal.component';
export * from './ok-modal/ok-modal.component';
export * from './video-modal/video-modal.component';
export * from './contact/contact.component';
