import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ok-modal',
  templateUrl: './ok-modal.component.html',
  styleUrls: ['./ok-modal.component.scss']
})
export class OkModalComponent {
  constructor(public dialogRef: MatDialogRef<OkModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClickOk() {
    this.dialogRef.close(true);
  }
}
