import { Subject } from 'rxjs';

export const loadingSource = new Subject();
export const loading$ = loadingSource.asObservable();

export const successSource = new Subject();
export const success$ = successSource.asObservable();

export const errorSource = new Subject();
export const error$ = errorSource.asObservable();
