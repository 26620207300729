import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class RootComponent {
  constructor(public router: Router) {
    if (environment.googleAnalytics.enabled) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalytics.trackingCode, {
            page_path: event.urlAfterRedirects
          });
        }
      });
    }
  }
}
