import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface SwitcherOption {
  icon: string;
  title: string;
}

export enum DisabledOption {
  FIRST = 0,
  SECOND = 1,
  NONE = 2
}

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent {
  @Input() first: SwitcherOption;
  @Input() second: SwitcherOption;
  @Input() active: boolean;
  @Input() firstChecked = true;
  @Input() disabled: DisabledOption;
  /** Event indicates true if first is checked, false if second is checked */
  @Output() toggle = new EventEmitter<boolean>();

  constructor() {}

  doToggle(event: MouseEvent) {
    if (!this.active) {
      return;
    }

    // Prevent event from bubbling: we're the only ones to get it!
    event.stopPropagation();

    if (this.disabled === DisabledOption.FIRST) {
      this.firstChecked = false;
    } else if (this.disabled === DisabledOption.SECOND) {
      this.firstChecked = true;
    } else {
      this.firstChecked = !this.firstChecked;
    }

    this.toggle.emit(this.firstChecked);
  }

  getClasses(option: SwitcherOption, disabled: DisabledOption): string[] {
    const classes = [];

    if (option) {
      if (option.icon) {
        classes.push(option.icon);
      }

      if (
        (disabled === DisabledOption.FIRST && option === this.first) ||
        (disabled === DisabledOption.SECOND && option === this.second)
      ) {
        classes.push('disabled');
      }
    }

    return classes;
  }
}
