// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: '__api_url__',
  cartRefreshDelay: Number('__cart_refresh_delay__'),
  keycloak: {
    url: '__keycloak_url__',
    realm: '__keycloak_realm__',
    clientId: '__keycloak_front_client_id__'
  },
  google: {
    apiUrl: '__google_maps_url__',
    keys: {
      autocomplete: '__google_autocomplete_key__',
      maps: '__google_maps_key__'
    }
  },
  appUrl: '__application_url__',
  sentry: {
    dsn: '__sentry_dsn__',
    environment: '__sentry_env__'
  },
  googleAnalytics: {
    enabled: '__ga_enabled__',
    trackingCode: '__ga_tracking_code__'
  },
  prismicUrl: '__prismic_url__'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
