import { Resolve } from '@angular/router';
import { AccountService } from '../../shared/services';
import { KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';
import { ROLE_EXAMINATEUR, ROLE_MC, ROLE_RCR, ROLE_ROA } from '../../shared/utils/roles';
import {
  setActiveCandidates,
  setCandidatesCount,
  setCurrentUser,
  setShoppingCartCount
} from '../../shared/states/user/user.state';
import { combineLatest } from 'rxjs';

@Injectable()
export class AccountResolver implements Resolve<any> {
  constructor(private keycloakService: KeycloakService, private accountService: AccountService) {}

  resolve() {
    return new Promise(resolve => {
      this.keycloakService.isLoggedIn().then(isLoggedIn => {
        if (isLoggedIn) {
          if (this.keycloakService.getUserRoles().includes(ROLE_MC)) {
            this.loadUserCartCountAndCandidates(resolve);
          } else if (this.hasRightToBackoffice()) {
            this.redirectToBackoffice();
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    });
  }

  loadUserCartCountAndCandidates(resolve) {
    combineLatest(
      this.accountService.getUserInformation(),
      this.accountService.getCartCount(),
      this.accountService.getCandidatesForCurrentUser(),
      this.accountService.getCandidatesCount(),
      (user, cartCount, candidates, candCounts) => ({ user, cartCount, candidates, candCounts })
    ).subscribe(res => {
      setCurrentUser(res.user);
      setShoppingCartCount(res.cartCount);
      setActiveCandidates(res.candidates);
      setCandidatesCount(res.candCounts);
      resolve();
    });
  }

  hasRightToBackoffice() {
    return this.keycloakService
      .getUserRoles()
      .some(role => role === ROLE_EXAMINATEUR || role === ROLE_RCR || role === ROLE_ROA);
  }

  redirectToBackoffice() {
    window.location.assign(window.location.origin + '/admin');
  }
}
