import { HttpResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';

const getCDFilename = (contentDisposition: string): string => {
  const regex = /filename="([^"]+)"/;
  const match = regex.exec(contentDisposition);
  return match && match[1] ? match[1] : 'file.pdf';
};

export const downloadFile = (resp: HttpResponse<Blob>) => {
  const blob = new Blob([resp.body], { type: resp.body.type });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.target = '_blank';
  link.download = getCDFilename(resp.headers.get('Content-Disposition'));

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href);
};

export const substractHours = stringDate => {
  const date = new Date(stringDate);
  return date.setHours(date.getHours() - 2);
};

/**
 * All dates from the API are of the format yyyy-MM-dd.
 * Parse that into an actual Date object.
 */
export function apiStrToDate(dateString: string): Date {
  const parts = dateString.split(/\D/);
  return new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
}

/**
 * Transform a date into the format expected by the API (yyyy-MM-dd)
 */
export function dateToApiStr(d: Date): string {
  return formatDate(d, 'yyyy-MM-dd', 'fr-FR');
}

export function toDateTime(dateTime: string): string {
  return formatDate(dateTime, "dd/MM/yyyy à HH'h'mm", 'fr-FR');
}

export function toDate(dateTime: string): string {
  return formatDate(dateTime, 'dd/MM/yyyy', 'fr-FR');
}

export function toTime(dateTime: string): string {
  return formatDate(dateTime, "H'h'mm", 'fr-FR');
}

export function boolToString(b: boolean): string {
  return b ? 'true' : 'false';
}

export const POSTAL_CODE_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];
