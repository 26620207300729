import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONTACT, DRIVING_SCHOOL } from '../../utils/endPoints';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendContactEmail(message): Observable<any> {
    return this.http.post(CONTACT, message);
  }
}
