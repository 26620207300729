export const constantes = {
  ERRORS: {
    CODE: {
      UNKNOWN: 'UNKNOWN',
      CANNOT_FETCH_HORODATAGE: 'CANNOT_FETCH_HORODATAGE',
      ILLEGAL_ANSWER: 'ILLEGAL_ANSWER',
      INCORRECT_TIRAGE: 'INCORRECT_TIRAGE',
      NO_AVAILABLE_TIRAGE: 'NO_AVAILABLE_TIRAGE',
      NO_CURRENT_INSCRIPTION: 'NO_CURRENT_INSCRIPTION',
      NO_RESULTS: 'NO_RESULTS',
      NO_SUCH_ENTITY: 'NO_SUCH_ENTITY',
      NEPH_ALREADY_EXISTS: 'NEPH_ALREADY_EXISTS',
      USER_CREATION_EXCEPTION: 'USER_CREATION_EXCEPTION',
      ALREADY_REGISTERED: 'ALREADY_REGISTERED',
      INSCRIPTIONS_CLOSED_FOR_SESSION: 'INSCRIPTIONS_CLOSED_FOR_SESSION',
      NO_AVAILABLE_SEATS: 'NO_AVAILABLE_SEATS',
      NEPH_IN_NEIGHBORING_SESSION: 'NEPH_IN_NEIGHBORING_SESSION',
      WSETG_ERROR: 'WSETG_ERROR',
      NEPH_VALIDATION_FAILED: 'NEPH_VALIDATION_FAILED',
      CANDIDATE_NOT_ARCHIVABLE: 'CANDIDATE_NOT_ARCHIVABLE'
    }
  },
  STATUS: {
    OK: 'OK'
  }
};

export enum MC_CATEGORY {
  PARTICULIER = 'PARTICULIER',
  AUTO_ECOLE = 'AUTO_ECOLE',
  PARTENAIRE = 'PARTENAIRE'
}

export enum MC_FORME_JURIDIQUE {
  EI = 'EI',
  EIRL = 'EIRL',
  EURL = 'EURL',
  SA = 'SA',
  SARL = 'SARL',
  SAS = 'SAS',
  SASU = 'SASU',
  SC = 'SC',
  SNC = 'SNC',
  ASS = 'ASS'
}
