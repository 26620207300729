import { Injectable } from '@angular/core';
import Prismic from '@prismicio/client';
import PrismicDom from 'prismic-dom';
import { Article } from '../../models/news.model';
import { PRISMIC } from '../../utils/endPoints';

@Injectable({
  providedIn: 'root'
})
export class PrismicService {
  async getNews(): Promise<Article[]> {
    const prismicApi = await Prismic.getApi(PRISMIC);
    const documentQuery = await prismicApi.query(Prismic.Predicates.at('document.type', 'news'));
    return documentQuery.results.flatMap(document =>
      document.data.body.map(
        (item: { primary: { title: any; image: { url: string; alt: string } } }) =>
          new Article(this.asHtml(item.primary.title), item.primary.image)
      )
    );
  }

  private asHtml(richText: any) {
    return PrismicDom.RichText.asHtml(richText);
  }
}
