export class Article {
  readonly title: string;
  readonly image: Image;
  constructor(title: string, image: Image) {
    this.title = title;
    this.image = image;
  }
}

interface Image {
  url: string;
  alt: string;
}
