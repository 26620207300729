import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

// Google
export const GOOGLE_AUTOCOMPLETE = `${environment.google.apiUrl}/place/autocomplete/json`;
export const GOOGLE_DETAILS = `${environment.google.apiUrl}/place/details/json`;

// CE
export const CES = `${API_URL}/ces`;

// Regions
export const REGIONS = `${API_URL}/regions`;

// Departments
export const DEPARTMENTS = '../../ressources/departments.json';

// Candidates
export const ACCOUNT = `${API_URL}/account`;
export const CANDIDATES = `${ACCOUNT}/candidates`;
export const CANDIDATES_COUNT = `${ACCOUNT}/candidates-count`;
export const ARCHIVE_CANDIDATE = `${API_URL}/candidates/archive`;
export const REACTIVATE_CANDIDATE = `${API_URL}/candidates/unarchive`;
export const CART = `${ACCOUNT}/cart`;
export const CART_PAYMENT = `${CART}/pay`;
export const CART_INSCRIPTIONS = `${CART}/inscriptions`;
export const CART_COUNT = `${CART}/count`;
export const CHECK_WSETG = `${ACCOUNT}/check-wsetg`;

// Driving School
export const DRIVING_SCHOOL = `${API_URL}/aes`;

// Sessions
export const SESSIONS = `${API_URL}/sessions`;
export const COUNT_CES = `${SESSIONS}/countces`;
export const FIND_CES = `${SESSIONS}/findces`;
export const SESSIONS_FIND = `${SESSIONS}/find`;

// Inscriptions
export const INSCRIPTIONS = `${API_URL}/inscriptions`;
export const INSCRIPTIONS_SUMMARY = `${INSCRIPTIONS}/summary`;
export const RESULT = `${API_URL}/results`;

// Payment
export const PAYMENT = `${API_URL}/payments/info`;
export const JETONS = `${API_URL}/jetons`;

// Product
export const PRODUCTS = `${API_URL}/products`;

// Contact
export const CONTACT = `${API_URL}/contact`;

// Prismic
export const PRISMIC = environment.prismicUrl;
