import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { flatMap, filter, first } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

// Models
import { Region } from '../../models/regions.models';
import { Department } from '../../models/department.model';

// Utils
import { DEPARTMENTS, REGIONS } from '../../utils/endPoints';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  constructor(private httpClient: HttpClient) {}

  getRegions(): Observable<Region[]> {
    return this.httpClient.get<Region[]>(REGIONS);
  }

  getRegion(region: string): Observable<Region> {
    return this.getRegions().pipe(
      flatMap(regions => from(regions)),
      filter(r => r.id === +region),
      first()
    );
  }

  getDepartments(): Observable<Department[]> {
    return this.httpClient.get<Array<Department>>(DEPARTMENTS);
  }
}
