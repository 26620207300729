import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DRIVING_SCHOOL } from '../../utils/endPoints';
import { Page } from '../../models/pageable.models';
import { DrivingSchool } from '../../models/driving-school.model';

@Injectable({
  providedIn: 'root'
})
export class DrivingSchoolService {
  constructor(private http: HttpClient) {}

  getDrivingSchoolByPostalCode(postalCode: string): Observable<Page<DrivingSchool>> {
    const params = new HttpParams().set('search', postalCode).set('size', '300');
    return this.http.get<Page<DrivingSchool>>(DRIVING_SCHOOL, { params });
  }
}
