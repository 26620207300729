import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-modal',
  templateUrl: './yes-no-modal.component.html',
  styleUrls: ['./yes-no-modal.component.scss']
})
export class YesNoModalComponent {
  constructor(public dialogRef: MatDialogRef<YesNoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClickYes() {
    this.dialogRef.close(true);
  }

  onClickNo() {
    this.dialogRef.close(false);
  }
}
