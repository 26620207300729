import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Provider } from '@angular/core';

import { RootRoutingModule } from './root-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import keycloakInitializer from '../shared/services/keycloak/initializer';
import { RootComponent } from './root.component';
import { AccountResolver } from './resolver/account.resolver';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module';
import { SentryErrorHandler } from '../shared/services/sentry/sentry.service';
import { RoutingState } from '../shared/utils/routingState';
import { environment } from '../../environments/environment';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeFr);

const providers: Provider[] = [
  RoutingState,
  {
    provide: APP_INITIALIZER,
    useFactory: keycloakInitializer,
    multi: true,
    deps: [KeycloakService]
  },
  AccountResolver,
  {
    provide: LOCALE_ID,
    useValue: 'fr-FR'
  }
];

if (environment.sentry) {
  providers.push({ provide: ErrorHandler, useClass: SentryErrorHandler });
}

@NgModule({
  declarations: [RootComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'exacode-frontend-site' }),
    RootRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    SharedModule
  ],
  providers,
  bootstrap: [RootComponent]
})
export class RootModule {}
