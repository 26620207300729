import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ACCOUNT, INSCRIPTIONS, RESULT, SESSIONS } from '../../utils/endPoints';
import { Observable } from 'rxjs';
import { Session } from '../../models/session.models';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  constructor(private httpClient: HttpClient) {}

  getResult(inscriptionId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${RESULT}/${inscriptionId}`, { responseType: 'blob', observe: 'response' });
  }

  downloadConvocation(inscriptionId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${INSCRIPTIONS}/${inscriptionId}/convocation`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  getSessionDetails(sessionId: number): Observable<Session> {
    return this.httpClient.get<Session>(`${SESSIONS}/${sessionId}`);
  }

  getCandidatesInSession(sessionId: number): Observable<string[]> {
    return this.httpClient.get<string[]>(`${ACCOUNT}/insession/${sessionId}`);
  }
}
