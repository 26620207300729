import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Components
import * as sharedComponents from './components';
// Services
import * as sharedServices from './services';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { PaginatorComponent } from './components/paginator/paginator.component';

@NgModule({
  declarations: [...sharedComponents.components, GenericTableComponent, PaginatorComponent],
  entryComponents: [...sharedComponents.modals],
  providers: [...sharedServices.services],
  imports: [CommonModule, RouterModule, MatDialogModule, FormsModule, ReactiveFormsModule],
  exports: [...sharedComponents.components, GenericTableComponent, PaginatorComponent]
})
export class SharedModule {}
