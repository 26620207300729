import { AfterViewInit, Component, HostListener, ViewEncapsulation } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { YesNoModalComponent } from '../modals';
import { MatDialog } from '@angular/material/dialog';
import { ROOT } from '../../utils/routes';
import { getShoppingCartCount, setShoppingCartCount } from '../../states/user/user.state';
import { AccountService } from '../../services';
import { of } from 'rxjs';
import { delay, mergeMap, repeat } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements AfterViewInit {
  ROOT = ROOT;
  headerIsSticky = false;
  menuOpen = false;
  authenticated = false;

  constructor(
    private kcService: KeycloakService,
    private router: Router,
    private dialog: MatDialog,
    private accountService: AccountService
  ) {
    this.kcService.isLoggedIn().then(connected => {
      this.authenticated = connected;
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart || event instanceof NavigationEnd) {
          if (event instanceof NavigationEnd) {
            document.querySelector('body').classList.remove('menu-is-open');
            this.closeMenu();
          }
        }
      });
    });
  }

  ngAfterViewInit() {
    const menuButton = document.getElementById('menu-button');
    menuButton.addEventListener('click', () => {
      if (menuButton.getAttribute('aria-expanded') === 'true') {
        document.querySelector('body').classList.add('menu-is-open');
      } else {
        document.querySelector('body').classList.remove('menu-is-open');
      }
    });

    if (this.authenticated) {
      of({})
        .pipe(
          mergeMap(_ => this.accountService.getCartCount()),
          delay(environment.cartRefreshDelay),
          repeat()
        )
        .subscribe(success => {
          setShoppingCartCount(success);
        });
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkSticky() {
    const startHeaderSticky = document.getElementById('startHeaderSticky');
    if (startHeaderSticky) {
      this.headerIsSticky = document.getElementById('startHeaderSticky').offsetHeight < window.pageYOffset;
    } else {
      this.headerIsSticky = false;
    }
  }

  closeMenu() {
    this.menuOpen = false;
  }

  goToHome() {
    this.closeMenu();
    this.router.navigate(['']);
  }

  login() {
    this.closeMenu();
    this.router.navigate([ROOT.ACCOUNT.fullPath]);
  }

  signUp() {
    this.closeMenu();
    this.router.navigate([ROOT.SIGNUP.fullPath]);
  }

  goToAccount() {
    this.closeMenu();
    this.router.navigate([ROOT.ACCOUNT.fullPath]);
  }

  goToSessions(): void {
    this.closeMenu();
    this.router.navigate([ROOT.ACCOUNT.CANDIDATES.SESSIONS.fullPath]);
  }

  getCartCount(): number {
    return getShoppingCartCount();
  }

  showLogoutPopop() {
    const count = getShoppingCartCount();
    this.dialog
      .open(YesNoModalComponent, {
        data: {
          title: 'Se déconnecter ?',
          content:
            count > 0
              ? 'Les réservations dans votre panier ne sont pas finalisées.'
              : 'Souhaitez-vous vraiment vous déconnecter ?',
          yesLabel: 'Annuler',
          noLabel: 'Me déconnecter'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed === false) {
          this.logout();
        }
      });
  }

  logout() {
    this.closeMenu();
    const redirectUri = window.location.origin;
    this.kcService.logout(redirectUri);
  }
}
