import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getCurrentUserEmail, getCurrentUserFirstname, getCurrentUserLastname } from '../../../states/user/user.state';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactService } from '../../../services/contact/contact.service';
import { setErrorMessage, setSuccessMessage } from '../../../states/common/common.state';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  isLoading = false;

  constructor(public dialogRef: MatDialogRef<ContactComponent>, private contactService: ContactService) {}

  static open(dialog: MatDialog) {
    dialog.open(ContactComponent, {
      width: '60%',
      minWidth: '60%',
      maxWidth: '100%',
      maxHeight: '85%',
      panelClass: 'contact-modal'
    });
  }

  ngOnInit() {
    this.contactForm = new FormGroup({
      firstname: new FormControl(getCurrentUserFirstname(), [Validators.required, Validators.maxLength(80)]),
      lastname: new FormControl(getCurrentUserLastname(), [Validators.required, Validators.maxLength(80)]),
      email: new FormControl(getCurrentUserEmail(), [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.maxLength(80)
      ]),
      subject: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      content: new FormControl('', [Validators.required, Validators.maxLength(4096)])
    });
  }

  sendContactEmail() {
    this.isLoading = true;

    const message = {
      firstname: this.contactForm.get('firstname').value,
      lastname: this.contactForm.get('lastname').value,
      email: this.contactForm.get('email').value,
      subject: this.contactForm.get('subject').value,
      content: this.contactForm.get('content').value
    };

    this.contactService.sendContactEmail(message).subscribe(
      () => {
        this.manageSuccess();
        this.isLoading = false;
        this.dialogRef.close();
      },
      () => {
        this.manageError();
        this.isLoading = false;
        this.dialogRef.close();
      }
    );
  }

  manageSuccess() {
    const successMessage = 'Votre message a bien été envoyé et va être traité dans les plus brefs délais.';
    setSuccessMessage(successMessage);
  }

  manageError() {
    const errorMessage = "Votre message n'a pu être envoyé. Merci de réessayer ultérieurement.";
    setErrorMessage(errorMessage);
  }
}
