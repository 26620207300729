import { environment } from './../../../../environments/environment';
import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    if (environment.sentry) {
      Sentry.init({
        dsn: environment.sentry.dsn,
        environment: environment.sentry.environment
      });
    }
  }

  handleError(error) {
    console.log(error);
    Sentry.captureException(error.originalError || error);
  }
}
