import { HttpParams } from '@angular/common/http';

/**
 * Parameters to send to the backend for a page request
 */
export interface PageParams {
  /** The page number */
  page?: number;
  /** The size of each page */
  size?: number;
  /**
   * For sorting: either "property" or "property,dir" where dir is "asc" or "desc"
   */
  sort?: string;
}

/**
 * Encode the given pagination request for HTTP
 */
export function toHttpParams(pageParams: PageParams): HttpParams {
  let params = new HttpParams();
  if (pageParams.page) {
    params = params.set('page', `${pageParams.page}`);
  }
  if (pageParams.size) {
    params = params.set('size', `${pageParams.size}`);
  }
  if (pageParams.sort) {
    params = params.set('sort', `${pageParams.sort}`);
  }
  return params;
}

export interface Page<T> {
  /** The actual items of this page */
  content: T[];
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  /** Total number of elements in the entire list (of which this is just a slice) */
  totalElements: number;
  /** Total number of pages to contain the entire list */
  totalPages: number;
  /** Is this the last page? */
  last: boolean;
  /** (Maximum) size of a single page */
  size: number;
  /** 0-based index of this page (from 0 to totalPages-1) */
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  /** Number of elements in this particular page */
  numberOfElements: number;
  /** Is this the first page? */
  first: boolean;
  /** Is this page empty? */
  empty: boolean;
}

export function asPage<T>(items: T[]): Page<T> {
  return {
    empty: false,
    first: true,
    last: true,
    number: 0,
    numberOfElements: items.length,
    size: items.length,
    sort: undefined,
    totalElements: items.length,
    totalPages: 1,
    content: items,
    pageable: undefined
  };
}
