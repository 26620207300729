import { loadingSource, successSource, errorSource } from './actions/common.subjects';

const state = {
  loading: false,
  successMessage: '',
  errorMessage: ''
};

export const setLoading = loading => {
  state.loading = loading;
  loadingSource.next();
};
export const isLoading = () => state.loading;

export const setSuccessMessage = message => {
  state.successMessage = message;
  successSource.next();
};
export const getSuccessMessage = () => state.successMessage;

export const setErrorMessage = message => {
  state.errorMessage = message;
  errorSource.next();
};
export const getErrorMessage = () => state.errorMessage;
