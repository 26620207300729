import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Session } from '../../models/session.models';
import { SESSIONS_FIND } from '../../utils/endPoints';
import { Page } from '../../models/pageable.models';

@Injectable({
  providedIn: 'root'
})
export class ExaminationCenterService {
  constructor(private httpClient: HttpClient) {}

  findSessions(ceId: number, earliest: string, pageNumber: number, pageSize: number = 4): Observable<Page<Session[]>> {
    const params = new HttpParams()
      .set('ceId', ceId.toString())
      .set('earliest', earliest)
      .set('page', pageNumber.toString())
      .set('size', pageSize.toString());
    return this.httpClient.get<Page<Session[]>>(SESSIONS_FIND, { params });
  }
}
