import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-abstract-subscription',
  template: `
    <div></div>
  `
})
export class AbstractSubscriptionComponent implements OnDestroy {
  subscriptions: Subscription[];

  constructor() {
    this.subscriptions = [];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
