import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {
  @Input() totalPages: number;
  @Input() pageNumber: number;
  @Output() changePage = new EventEmitter<number>();

  validPageNumbers: Array<number>;

  ngOnChanges() {
    // TODO: make this an array of indices, and cut out unwanted ones from the middle
    // Then get rid of showPage()
    this.validPageNumbers = Array(this.totalPages);
  }

  showPage(index: number): boolean {
    return (
      (this.pageNumber < 5 && index < 10) ||
      (this.pageNumber - index >= 0 && this.pageNumber - index < 5) ||
      (index - this.pageNumber >= 0 && index - this.pageNumber <= 5)
    );
  }

  goToPreviousPage(): void {
    if (this.pageNumber > 0) {
      this.changePage.emit(this.pageNumber - 1);
    }
  }

  goToNextPage(): void {
    if (this.pageNumber + 1 < this.totalPages) {
      this.changePage.emit(this.pageNumber + 1);
    }
  }

  goToPage(page: number): void {
    this.changePage.emit(page);
  }
}
