import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export enum VisibilityMode {
  ALL_DEVICE = 0,
  SMALL_DEVICE_ONLY = 1,
  LARGE_DEVICE_ONLY = 2
}
export interface Column<T> {
  /** Label to show in the header */
  label: string;
  /** If table can be sorted on this column, provide a comparison function here. */
  sortFunc?: (a: Row<T>, b: Row<T>) => number;
  /** If sortable, is the direction ascending? */
  sortAsc?: boolean;
  /** Whether a narrow style should be used */
  narrow?: boolean;
  /** Text alignment */
  align?: 'left' | 'right' | 'center';
  /**
   * If the value to display is just a simple string, this function will return it.
   * For more complicated cells, provide a cell template.
   */
  getValueFor?: (row: Row<T>) => string;
  /**
   * Filter column visibility in function of device screen size
   */
  visibility?: VisibilityMode;
}

export interface Row<T> {
  /** Indicates that the "expansion" container should be activated  */
  enableExpansion?: boolean;
  /** The entity/object/whatever that this row represents */
  data: T;
  disabled?: boolean;
  selectable?: boolean;
}

/**
 * Helper to create simple rows: transforms a list of anything into rows of that thing
 */
export function toRows<T>(list: T[]): Row<T>[] {
  return list ? list.map(item => ({ data: item })) : [];
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent<T> {
  @ContentChild('cell', { static: false }) cellTemplateRef: TemplateRef<any>;
  @ContentChild('expansion', { static: false }) expansionTemplateRef: TemplateRef<any>;
  @Input() columns: Column<T>[] = [];
  @Input() rows: Row<T>[] = [];
  @Output() rowClick = new EventEmitter<{ row: Row<T>; index: number }>();

  constructor() {}

  getAlignClass(column: Column<T>): string {
    if (column.align) {
      return 'u-text-' + column.align;
    } else {
      return this.columns.indexOf(column) !== this.columns.length - 1 ? 'u-text-left' : 'u-text-center';
    }
  }

  getHeaderClasses(column: Column<T>) {
    const classes = ['cand-accordion__title__element', 'u-bold', this.getAlignClass(column)];

    if (column.narrow) {
      classes.push('cand-accordion__title__element__narrow');
    }

    if (column.sortFunc) {
      classes.push('col-sort');
    }

    if (column.visibility === VisibilityMode.SMALL_DEVICE_ONLY) {
      classes.push('cand-accordion__title__element__small_device');
    }

    if (column.visibility === VisibilityMode.LARGE_DEVICE_ONLY) {
      classes.push('cand-accordion__title__element__large_device');
    }

    return classes.join(' ');
  }

  getOuterRowClasses(row: Row<T>) {
    const classes = ['c-accordion__title', 'no-expand', 'js-accordion'];

    if (row.disabled) {
      classes.push('disabled-line');
    } else if (row.selectable) {
      classes.push('selectable-line');
    }

    return classes.join(' ');
  }

  getInnerRowClasses(row: Row<T>, column: Column<T>) {
    const classes = ['cand-accordion__title__element'];

    if (column.narrow) {
      classes.push('cand-accordion__title__element__narrow');
    }

    if (column.visibility === VisibilityMode.SMALL_DEVICE_ONLY) {
      classes.push('cand-accordion__title__element__small_device');
    }

    if (column.visibility === VisibilityMode.LARGE_DEVICE_ONLY) {
      classes.push('cand-accordion__title__element__large_device');
    }

    return classes.join(' ');
  }

  onClickRow(row: Row<T>, index: number) {
    if (!row.disabled) {
      this.rowClick.emit({ row, index });
    }
  }

  onHeaderClick(column: Column<T>) {
    if (column.sortFunc) {
      column.sortAsc = !column.sortAsc;
      const dir = column.sortAsc ? 1 : -1;
      this.rows = [...this.rows].sort((a, b) => dir * column.sortFunc(a, b));
    }
  }
}
