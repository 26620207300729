export interface Place {
  address_components?: AddressComponent[];
  adr_address?: string;
  formatted_address?: string;
  geometry?: Geometry;
  icon?: string;
  id?: string;
  name?: string;
  photos?: Array<any>;
  place_id?: string;
  reference?: string;
  scope?: string;
  types?: Array<any>;
  url?: string;
  utc_offset?: number;
  vicinity?: string;
  website?: string;
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export const ADDR_COMPONENTS = Object.freeze({
  STREET_NUMBER: 'street_number',
  STREET_NAME: 'route',
  CITY: 'locality',
  POSTAL_CODE: 'postal_code',
  DEPARTMENT: 'administrative_area_level_1',
  REGION: 'administrative_area_level_2',
  COUNTRY: 'country'
});

export interface Geometry {
  location: Location;
  viewport: any;
}

export interface Location {
  lat: number;
  lng: number;
}

export interface PlaceResult {
  result: Place;
  status: string;
}
