import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

// Utils
import { GOOGLE_AUTOCOMPLETE, GOOGLE_DETAILS } from '../../utils/endPoints';

// Models
import { PlaceApi } from '../../models/predictions.models';
import { PlaceResult } from '../../models/google.models';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  constructor(private http: HttpClient) {}

  getPlaceAutocomplete(input: string): Observable<PlaceApi> {
    const key = environment.google.keys.autocomplete;
    const params = { key, input, components: 'country:fr' };
    return this.http.get<PlaceApi>(GOOGLE_AUTOCOMPLETE, { params });
  }

  getPlaceDetails(placeid: string): Observable<PlaceResult> {
    const key = environment.google.keys.autocomplete;
    const params = { placeid, key };
    return this.http.get<PlaceResult>(GOOGLE_DETAILS, { params });
  }
}
