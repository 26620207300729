import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce, flatMap } from 'rxjs/operators';
import { of, Subject, timer } from 'rxjs';
import { FormControl } from '@angular/forms';

// Services
import { GoogleService } from '../../services';

// Models
import { Prediction } from 'src/app/shared/models/predictions.models';
import { ADDR_COMPONENTS } from 'src/app/shared/models/google.models';

// Utils
import { constantes } from 'src/app/shared/utils/constants';

// Components
import { AbstractSubscriptionComponent } from '../abtract-subscription/abstract-subscription.component';

export interface SearchResult {
  street?: string;
  city?: string;
  postalCode?: string;
  region?: string;
  latitude: number;
  longitude: number;
  place_id: string;
  description: string;
}

@Component({
  selector: 'app-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.scss']
})
export class PlaceAutocompleteComponent extends AbstractSubscriptionComponent implements OnInit {
  /** Affects the styling: do we use a white background, or clear and show the pin icon? */
  @Input() formMode = true;
  _defaultAddress: string;
  @Input() label = '';
  @Input() showLocationIcon = false;
  @Output() select = new EventEmitter<SearchResult>();
  predictions: Prediction[];
  readonly search: Subject<string> = new Subject();
  inputFormControl: FormControl = new FormControl();

  constructor(private googleService: GoogleService) {
    super();
  }

  ngOnInit() {
    this.predictions = [];
    this.subscriptions.push(
      this.search
        .pipe(
          debounce(() => timer(300)),
          flatMap(searchText => {
            if (searchText && searchText.length >= 3) {
              return this.googleService.getPlaceAutocomplete(searchText);
            } else {
              return of({ status: null, predictions: null });
            }
          })
        )
        .subscribe(res => {
          const { status, predictions } = res;
          this.predictions = this.isStatusOk(status) ? predictions : [];
        })
    );
  }

  @Input()
  set defaultAddress(text: string) {
    this._defaultAddress = text;
    this.inputFormControl.setValue(text);
  }

  reset() {
    this.inputFormControl.setValue(null);
  }

  showPredictions(): boolean {
    return this.predictions.length > 0;
  }

  isClearInput() {
    this.inputFormControl.setValue('');
  }

  onKeyUp(event) {
    this.search.next(event.target.value);
  }

  isStatusOk(status: string): boolean {
    return status && status === constantes.STATUS.OK;
  }

  selectPrediction(prediction: Prediction) {
    this.predictions = [];
    this.inputFormControl.setValue(prediction.description);
    this.emitAddress(prediction);
  }

  emitAddress(prediction: Prediction) {
    this.subscriptions.push(
      this.googleService.getPlaceDetails(prediction.place_id).subscribe(res => {
        const event: SearchResult = {
          latitude: res.result.geometry.location.lat,
          longitude: res.result.geometry.location.lng,
          place_id: prediction.place_id,
          description: prediction.description
        };

        res.result.address_components.forEach(comp => {
          if (comp.types.includes(ADDR_COMPONENTS.CITY)) {
            event.city = comp.long_name;
          } else if (comp.types.includes(ADDR_COMPONENTS.STREET_NUMBER)) {
            event.street = event.street ? `${comp.long_name} ${event.street}` : comp.long_name;
          } else if (comp.types.includes(ADDR_COMPONENTS.STREET_NAME)) {
            event.street = event.street ? `${event.street} ${comp.long_name}` : comp.long_name;
          } else if (comp.types.includes(ADDR_COMPONENTS.POSTAL_CODE)) {
            event.postalCode = comp.long_name;
          } else if (comp.types.includes(ADDR_COMPONENTS.REGION)) {
            event.region = comp.long_name;
          }
        });

        this.select.emit(event);
      })
    );
  }
}
