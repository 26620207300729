/**
 * Paths to all routes in the application. Use these constants to avoid
 * hard-coding route names.
 */
export const ROOT = {
  path: '',
  fullPath: '',
  SIGNUP: { path: 'signup', fullPath: '' },
  CART: {
    path: 'cart',
    fullPath: '',
    ONE_OF: {
      path: ':id',
      fullPath: ''
    }
  },
  EXAM_CENTER: {
    path: 'centre-d-examen',
    fullPath: '',
    NOT_FOUND: {
      path: 'aucun-centre',
      fullPath: ''
    }
  },
  ABOUT: { path: 'qui-sommes-nous', fullPath: '' },
  NEWS: { path: 'actualites', fullPath: '' },
  WORKING: { path: 'comment-ca-marche', fullPath: '' },
  LEGAL: { path: 'mentions-legales', fullPath: '' },
  PRIVACY: { path: 'politique-de-confidentialite', fullPath: '' },
  ACCOUNT: {
    path: 'account',
    fullPath: '',
    CANDIDATES: {
      path: 'candidates',
      fullPath: '',
      SESSIONS: {
        path: 'sessions',
        fullPath: '',
        JUSTIFY: { path: ':idSession/justify', fullPath: '' }
      },
      LIST: {
        path: 'candidate',
        fullPath: '',
        ADD: { path: 'add', fullPath: '' },
        EDIT: { path: ':id', fullPath: '' }
      }
    },
    ME: {
      path: 'me',
      fullPath: '',
      INFO: { path: 'informations', fullPath: '' },
      PAYMENTS: { path: 'paiements', fullPath: '' }
    }
  }
};

// Add parents to routes
function setFullPaths(parent) {
  Object.keys(parent).forEach(key => {
    if (typeof parent[key] === 'object') {
      // this is a child route definition
      parent[key].fullPath = parent.fullPath + '/' + parent[key].path;
      setFullPaths(parent[key]);
    }
  });
}

setFullPaths(ROOT);

export const routeWithId = (fullPath: string, id: string | number): string => {
  return fullPath.replace(/:[a-zA-Z0-9]+/, '' + id);
};
