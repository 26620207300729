import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Breadcrumb } from './breadcrumb.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements AfterViewInit {
  @ViewChild('breadcrumbList', { static: true }) breadcrumbList: ElementRef;
  items: Breadcrumb[] = [];

  constructor(private route: ActivatedRoute) {
    const bc: Breadcrumb[] = [];

    // Step 1: walk up the Router tree hierarchy to build up the breadcrumb items
    while (route) {
      const url = route.snapshot.url.join('');
      const label = route.snapshot.data.label;
      if (url || (bc.length && label)) {
        bc.push({
          routerLink: url,
          value: route.snapshot.data.label
        });
      }
      route = route.parent;
    }

    bc.reverse();

    // The problem with the above is that it only gives us single pieces of the
    // path. We have to manually put it all back together, with slashes, to get
    // actual usable URLs.
    let path = '';
    bc.forEach(crumb => {
      if (crumb.routerLink) {
        path = path + '/' + crumb.routerLink;
      }
      crumb.routerLink = path;
    });
    this.items = bc;
  }

  ngAfterViewInit() {
    // on mobile scroll x to show last item
    if (this.items && this.items.length > 0) {
      this.breadcrumbList.nativeElement.scrollTo(this.breadcrumbList.nativeElement.scrollWidth, 0);
    }
  }
}
