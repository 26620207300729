import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Candidate, CandidatesCount, CreateCandidate, SNUStatus, UpdateCandidate } from '../../models/candidate.model';
import { Cart } from '../../models/cart';
import { CartPayment, ShoppingCartPaymentCommand } from '../../models/cart-payment.model';
import { AccountInscriptionsSummary, CancelInscriptionResult, Inscription } from '../../models/inscription.model';
import { Page, PageParams, toHttpParams } from '../../models/pageable.models';
import { ProductSelection } from '../../models/product';
import { User, UserPersonalInfo } from '../../models/user.models';
import { setShoppingCartCount } from '../../states/user/user.state';
import {
  ACCOUNT,
  ARCHIVE_CANDIDATE,
  CANDIDATES,
  CANDIDATES_COUNT,
  CART,
  CART_COUNT,
  CART_INSCRIPTIONS,
  CART_PAYMENT,
  CHECK_WSETG,
  INSCRIPTIONS,
  INSCRIPTIONS_SUMMARY,
  REACTIVATE_CANDIDATE
} from '../../utils/endPoints';
import { boolToString, dateToApiStr } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient) {}

  sendSignUp(email: string) {
    return this.http.post(ACCOUNT, { email });
  }

  getUserInscriptions(
    pageParams: PageParams,
    active: boolean,
    searchTerm?: string,
    searchDate?: Date
  ): Observable<Page<Inscription>> {
    let params = toHttpParams(pageParams).set('active', boolToString(active));
    if (searchTerm) {
      params = params.set('search', searchTerm);
    }
    if (searchDate) {
      params = params.set('searchDate', dateToApiStr(searchDate));
    }

    return this.http.get<Page<Inscription>>(INSCRIPTIONS, { params });
  }

  getUserInscriptionsSummary(): Observable<AccountInscriptionsSummary> {
    return this.http.get<AccountInscriptionsSummary>(INSCRIPTIONS_SUMMARY);
  }

  getUserSessionsInCart(): Observable<Inscription[]> {
    return this.http.get<Inscription[]>(CART_INSCRIPTIONS);
  }

  justifyCandidateAbsence(idSession: number, explanation: string, files: File[]) {
    const frmData = new FormData();
    for (const file of files) {
      frmData.append('justifications', file);
    }
    frmData.append('explanation', explanation);

    return this.http.post(`${INSCRIPTIONS}/${idSession}/justify`, frmData);
  }

  cancelInscription(inscriptionId: number): Observable<CancelInscriptionResult> {
    return this.http.delete<CancelInscriptionResult>(`${INSCRIPTIONS}/${inscriptionId}/cancel`, {
      responseType: 'text'
    } as object);
  }

  reimburseCredit() {
    return this.http.patch(`${ACCOUNT}/reimburse`, null);
  }

  getUserInformation(): Observable<User> {
    return this.http.get<User>(`${ACCOUNT}/info`);
  }

  getCandidatesForCurrentUser(archived = false): Observable<Candidate[]> {
    return this.http.get<Candidate[]>(CANDIDATES, {
      params: new HttpParams().set('archived', boolToString(archived))
    });
  }

  addCandidateForCurrentUser(candidate: CreateCandidate) {
    return this.http.post(CANDIDATES, candidate);
  }

  updateCandidateForCurrentUser(candidateId: string, candidate: UpdateCandidate): Observable<Candidate> {
    return this.http.patch<Candidate>(`${CANDIDATES}/${candidateId}`, candidate);
  }

  archiveCandidateForCurrentUser(candidateId: string): Observable<void> {
    return this.http.patch<void>(`${ARCHIVE_CANDIDATE}/${candidateId}`, {});
  }

  reactivateCandidateForCurrentUser(candidateId: string): Observable<void> {
    return this.http.patch<void>(`${REACTIVATE_CANDIDATE}/${candidateId}`, {});
  }

  getCart(): Observable<Cart> {
    return this.http.get<Cart>(CART);
  }

  getCartCount(): Observable<number> {
    return this.http.get<number>(CART_COUNT);
  }

  addToCart(sessionId: number, productSelection: ProductSelection): Observable<any> {
    const selections = Object.keys(productSelection).map(candidateId => {
      return {
        candidateId,
        productId: productSelection[candidateId].id
      };
    });
    return this.http.post(CART, { sessionId, selections });
  }

  payForCart(command: ShoppingCartPaymentCommand): Observable<CartPayment> {
    return this.http.patch<CartPayment>(CART_PAYMENT, command);
  }

  updatePersonalInfo(personalInfo: UserPersonalInfo): Observable<User> {
    return this.http.put<User>(`${ACCOUNT}/info`, personalInfo);
  }

  updateCartCount(): Subscription {
    return this.getCartCount().subscribe(cartCount => setShoppingCartCount(cartCount));
  }

  checkSNUStatus(candidateId: string, sessionDate: Date): Observable<SNUStatus> {
    return this.http.get<SNUStatus>(`${CANDIDATES}/${candidateId}/snu`, {
      params: new HttpParams().set('sessionDate', dateToApiStr(sessionDate)),
      responseType: 'text'
    } as object);
  }

  getCandidatesCount(): Observable<CandidatesCount> {
    return this.http.get<CandidatesCount>(CANDIDATES_COUNT);
  }

  checkWSETG(): Observable<boolean> {
    return this.http.get<CandidatesCount>(CHECK_WSETG).pipe(
      map(() => true),
      catchError(err => {
        if (err.status == 417) {
          // This is expected
          return of(false);
        } else {
          // This is a problem
          return throwError(err);
        }
      })
    );
  }
}
